import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import { FiArrowLeft } from "react-icons/fi";

export default ({ data }) => {
  const post = data.markdownRemark;
  const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;

  return (
    <Layout>
      <div className="section">
        <div
          className="container"
          style={{
            borderBottom: "5px solid #325cc3",
            padding: "2rem 0.2rem 2rem 0.2rem",
          }}
        >
          <div className="columns">
            <div className="column">
              <Link to="/blog" className="has-text-primary">
                <FiArrowLeft /> Back to blog
              </Link>
              <h1
                style={{
                  padding: "2rem 0",
                  lineHeight: 1.2,
                  maxWidth: "500px",
                }}
              >
                {post.frontmatter.title}
              </h1>
              <p style={{ paddingBottom: "3rem" }}>
                {post.frontmatter.subTitle}
              </p>

              <p style={{ paddingBottom: "0.1rem", fontSize: "1rem" }}>
                By: {post.frontmatter.author}
              </p>
              <p style={{ paddingTop: "0.1rem", fontSize: "1rem" }}>
                Last Updated: {post.frontmatter.date}
              </p>
            </div>
            <div className="column">
              <Img fluid={featuredImgFluid} style={{ borderRadius: "10px" }} />
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: "800px", paddingTop: "3rem" }}
        >
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subTitle
        tags
        author
        date(formatString: "DD MMMM, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
